import { Card, Col, Row, Button, message } from 'antd';
import { SyncOutlined } from '@ant-design/icons';

// Hooks
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

// API
import APIService from '@/services/API';

const HomeScreen = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState({
    slug: 'leading-config',
  });
  const [leadingId, setLeadingId] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [loading, setLoading] = useState(false);

  const { isLoading, data, refetch } = useQuery(['configs', params], () =>
    APIService.getConfigs(params),
  );

  useEffect(() => {
    if (data?.data.length) {
      const firstData = data.data[0];
      const elementId = firstData.elements.find((item) => item.slug == 'leadingre_id');
      if (elementId) {
        setLeadingId(elementId.value);
      }

      const elementUpdated = firstData.elements.find((item) => item.slug == 'updated_at');
      if (elementUpdated) {
        const date = new Date(elementUpdated.value);
        setUpdatedAt(date.toLocaleDateString());
      }
    }
  }, [data]);

  const onSyncronize = async () => {
    setLoading(true);
    const response = await APIService.syncLeading();
    if (response.ok) {
      refetch();
      message.success(t('dashboard.successLeadingMessage'));
    } else {
      message.error(response.data?.message);
    }
    setLoading(false);
  };

  return (
    <div className="dashboard-screen fadeIn">
      <Row>
        <Col>
          <h2 style={{ marginBottom: 10 }}>{t('dashboard.title')}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card
            loading={isLoading}
            actions={[
              <Button loading={loading} key="sendBtn" type="link" onClick={onSyncronize}>
                <SyncOutlined /> {t('dashboard.send')}
              </Button>,
            ]}
            style={{ minWidth: 320 }}
          >
            <Card.Meta
              title={t('dashboard.syncLeading')}
              description={
                <>
                  <p>
                    <strong>Leading ID: </strong>
                    {leadingId}
                  </p>
                  <p>
                    <strong>Last Updated: </strong>
                    {updatedAt}
                  </p>
                </>
              }
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HomeScreen;
